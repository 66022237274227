<template>
  <div class="loginConiter">
    <div class="leftbg"></div>
    <div class="loginlabe">
      <div class="lotitle">
        <div class="secong">江苏省工程建设地方标准编制管理系统</div>
        <div class="first">{{ !isForgot ? '用户登录' : '忘记密码' }}</div>
      </div>
      <div class="shuru">
        <div class="zhanghu">
          <div class="tie">账号</div>
          <el-input placeholder="新企业账户为邮箱地址" @keyup.native.enter="user_login" v-model="username" clearable>
          </el-input>
        </div>
        <div v-if="!isForgot">
          <div class="zhanghu">
            <div class="tie">密码</div>
            <el-input placeholder="新企业密码为邮箱加手机号拼接" v-model="password" show-password
              @keyup.native.enter="user_login"></el-input>
          </div>
        </div>
        <div v-if="isForgot">
          <div class="zhanghu">
            <div class="tie">电话</div>
            <el-input placeholder="请输入电话" v-model="phone" clearable>
            </el-input>
          </div>
          <div class="zhanghu">
            <div class="tie">邮箱</div>
            <el-input placeholder="请输入邮箱" v-model="email" clearable>
            </el-input>
          </div>
        </div>
        <div class="zhanghu">
          <div class="tie">验证码</div>
          <el-input placeholder="请输入验证码" v-model="imageCode" @keyup.native.enter="user_login">
            <template slot="append">
              <div class="login-code" @click="refreshCode">
                <canvas id="canvas" height="40" width="116"></canvas>
              </div>
            </template>
          </el-input>
        </div>
        <div class="commit">
          <div @click="user_login" class="logincomit">{{ !isForgot ? '登录' : '提交密码重置申请' }}</div>
          <!-- <div class="cont">点击登录即代表同意《服务协议》</div> -->
        </div>
        <div class="logcont2">
          <!-- <div class="forget">忘记密码</div> -->
          <div class="forget">还没账号？
            <span style="color:#747575;text-decoration: none;" v-if="!isRegister">点击注册</span>
            <router-link to="../register" v-if="isRegister">
              <span style="color:#3082E2;text-decoration: none;">点击注册</span>
            </router-link>
            <span class="forgetPass" @click="forgetPassword">忘记密码</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-dialog :visible.sync="modifyVisible" custom-class="news_dialog" :close-on-click-modal="false" title="修改密码">
        <div>
          <el-form ref="userFrom">
            <el-form-item label="账户">
              <el-input placeholder="请输入账户" v-model="userFrom.username">
              </el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input placeholder="请输入密码" v-model="userFrom.password" show-password>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submit()">确 定</el-button>
          <el-button @click="infoCancle()">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { login, resetPass } from "../api/user";
import { standardTimeList } from "../api/project";
import moment from "moment";
import Vue from 'vue'
export default {
  data() {
    return {
      username: "",
      password: "",
      url: "https://gbservice.cn/#/register",
      imageCode: '',
      verifyCode: '',
      isRegister: false,
      isForgot: false,
      modifyVisible: false,
      userFrom: {
        username: "",
        password: "",
        default_password:""
      }
    };
  },
  mounted() {
    // console.log('默认密码', this.$route.query.default)
    this.userFrom.default_password = this.$route.query.default
    this.verifyCode = this.createCanvas();
    this.queryStandardTime()
  },
  methods: {
    toRescue() {
      window.open("https://gbservice.cn/#/register", "_blank");
    },
    user_login() {
      // debugger
      if (this.imageCode != this.verifyCode) {
        this.$message.error('验证码错误!');
        return;
      }
      let that = this;
      if (
        this.username &&
        this.username != "" &&
        this.password &&
        this.password != ""
      ) {
        let params = {
          username: this.username,
          password: this.password,
        };
        login(params).then((res) => {
          // console.log(res, '登录的返回');
          if (
            res.data.type &&
            (res.data.type == 1 || res.data.type == 2 || res.data.type == 3)
          ) {
            that.$store.commit("set_user", res.data);
            that.$router.push({
              path: "/",
            });
          } else {
            that.$message.warning("普通用户暂不支持登录");
          }
        }).catch(err => {
          // this.modifyVisible = true
          this.printResponse(err)
          // that.$message.warning(err);
          // console.log(err, '返回的错误信息');
        });
      } else {
        this.$message.warning("请填写用户名和密码");
      }
    },
    refreshCode() {
      this.verifyCode = this.createCanvas();
    },
    rn(min, max) {
      return parseInt((Math.random() * (max - min) + min).toString());
    },
    rc(min, max) {
      let r = this.rn(min, max);
      let g = this.rn(min, max);
      let b = this.rn(min, max);
      return `rgb(${r},${g},${b})`;
    },
    createCanvas() {
      let ctx = document.getElementById('canvas').getContext('2d');
      ctx.fillStyle = this.rc(180, 230);
      ctx.fillRect(0, 0, 116, 40);
      let pool = '0123456789';
      let code = '';
      for (let i = 0; i < 4; i++) {
        let c = pool[this.rn(0, pool.length)];
        let fs = this.rn(24, 30);
        let deg = this.rn(-30, 30);
        ctx.font = fs + 'px sans-serif';
        ctx.textBaseline = 'top';
        ctx.fillStyle = this.rc(0, 200);
        ctx.save();
        ctx.translate(30 * i + 10, this.rn(12, 20));
        ctx.rotate((deg * Math.PI) / 180);
        ctx.fillText(c, 0, 0);
        ctx.restore();
        code += c;
      }
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(this.rn(0, 116), this.rn(0, 40));
        ctx.lineTo(this.rn(0, 116), this.rn(0, 40));
        ctx.strokeStyle = this.rc(0, 160);
        ctx.closePath();
        ctx.stroke();
      }
      for (let i = 0; i < 40; i++) {
        ctx.beginPath();
        ctx.arc(this.rn(0, 116), this.rn(0, 40), 1, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fillStyle = this.rc(0, 200);
        ctx.fill();
      }
      return code;
    },
    queryStandardTime() {
      let nowDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss"); //当前时间
      standardTimeList({ type: 1 }).then((res) => {
        // console.log(nowDate, '当前时间')
        // console.log('打印时间', res.data)
        if (nowDate < res.data[0].start_time || nowDate > res.data[0].end_time) {
          //如果现在的时间小于开始或者大于结束
          this.isRegister = false
        } else {
          this.isRegister = true
        }
      });
    },
    forgetPassword() {
      // this.isForgot = true
      let key = 'forget'
      this.$router.push({
        path: "/register",
        query:{key:key}
      });
    },
    infoCancle() {
      this.modifyVisible = false
    },
    printResponse() {
      // console.log(Vue.prototype.code, '打印报错');
      if (Vue.prototype.code === 401) {
        // console.log('无权限')
      } else if (Vue.prototype.code === 402) {
        this.modifyVisible = true
      }
    },
    //提交
    submit() {
      let data = { ... this.userFrom }
      resetPass(data).then((res) => {
        // console.log(res, '修改密码的返回');
        if (res.code === 200) {
          this.$message.success("密码修改成功");
          this.modifyVisible = false
        }
      }).catch(err => {

      });
    }
  },
};
</script>
<style scoped>
/deep/.el-input--suffix .el-input__inner {
  /* width: 398px; */
  /* height: 60px; */
  /* font-size: 20px;
  color: #afafaf; */
}

/deep/.el-input-group__append {
  /* width: 574px; */
  padding: 0 !important;
}
</style>
<style scoped lang="scss">
.loginConiter {
  // background: url("https://cloud.cdmp.tech/31f0051c1a494a9d745a3471db9831f4.png")
  //   no-repeat 0 0;
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  background-size: 100% 100%;
}



.login-code {
  height: 38px;
}

.loginlabe {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // width: 900px;
  // margin: 0 auto;
  // padding-top: 170px;
  .lotitle {
    .first {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      margin: 40px 0;
      text-align: center;
    }

    .secong {
      font-size: 36px;
      font-weight: 800;
      color: #000000;
      // margin-bottom: 70px;
      text-align: center;
    }
  }

  .shuru {
    // padding-top: 90px;
    margin: 0 auto;
    width: 360px;
    height: 360px;
    // background: rgba(234, 238, 245, 0.8);
    border-radius: 20px;
    // padding: 70px;

    .zhanghu {
      // display: flex;
      // flex-direction: row;
      border-bottom: 1px solid #ffffff;
      margin-bottom: 24px;

      .tie {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 10px;
      }
    }
  }

  .logcont {
    margin-top: 27px;
    // width: 574px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .forget {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }
  }

  .logcont2 {
    margin-top: 27px;

    // width: 400px;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    .forget {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      text-align: center;
    }

    .forgetPass {
      color: #3082E2;
      cursor: pointer;
      padding-left: 11px;
    }
  }

  .commit {
    margin-top: 57px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logincomit {
      width: 378px;
      height: 40px;
      background: #3082e2;
      color: #ffffff;
      font-size: 18px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      border-radius: 5px;
    }

    .cont {
      width: 574px;
      margin-top: 24px;
      font-size: 20px;
      color: #979797;
      text-align: center;
      cursor: pointer;
    }
  }
}

.leftbg {
  width: 50%;
  height: 100%;
  background-color: #3082e2;
}
</style>
